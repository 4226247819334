import React from 'react';
import ReactDOM from 'react-dom';
import MomentaneousValues from './momentaneous-values/MomentaneousValues';
import BlobDownload from './blob-download/BlobDownload';
import ParameterValueDifferences from './parameter-value-differences/ParameterValueDifferences';

window.stuns = {
    momentaneousValues: domElement => ReactDOM.render(<MomentaneousValues />, domElement),
    blobDownload: domElement => ReactDOM.render(<BlobDownload />, domElement),
    parameterValueDifferences: domElement => ReactDOM.render(<ParameterValueDifferences />, domElement)
};
