import React from 'react';

const _constants = {
    scriptBaseUrl: "https://stunssolar.blob.core.windows.net/web"
};

export const createParameterDefinitions = () => {
    return {
        totalYield: {
            description: "Total mängd producerad solenergi",
            icon: _constants.scriptBaseUrl + "/svg/solar-energy.svg",
            formatValue: function (value) { return formatNumber(value, 0) + " kWh"; }
        },

        //totalElectricCarDistance: {
        //    description: "Motsvarande elbilsmil all solenergi räcker till",
        //    icon: _constants.scriptBaseUrl + "/svg/electric-car.svg",
        //    getValue: function (data) { return data.totalYield / 1.7; },
        //    formatValue: function (value) { return formatNumber(value, 0) + " mil"; }
        //},

        // Vi räknar med att ett genomsnittligt svenskt träd binder in 14 kg CO2 per år.
        // Angående hur en kWh värderas kan man antingen gå den officiella utsläppskoefficienten vi köper in (vattenkraft) som är på 8,23 g CO2/ kWh.
        // Annars kan man gå på svensk elmix om 100 g CO2 / kWh(har för mig vi har beslutat om det senare).
        // Då blir väl
        // 1 kWh el = 100 g CO2 / kWh = 0,1 kg CO2 / kWh = 0,1 / 14 träd * år / kWh = 0,00714 träd * år / kWh el
        totalNumberOfTrees: {
            description: "Minskade koldioxidutsläpp omräknat till träd per år",
            icon: _constants.scriptBaseUrl + "/svg/tree.svg",
            getValue: data => 0.1 / 14 * data.totalYield,
            formatValue: value => formatNumber(value, 0) + " träd"
        },
        power: {
            description: "Aktuell solelproduktion",
            icon: _constants.scriptBaseUrl + "/svg/solar-energy.svg",
            formatValue: value => formatNumber(value, 0) + " kW"
        },
        predictedPower: {
            description: "Predikterad solelproduktion om 5 minuter",
            icon: _constants.scriptBaseUrl + "/svg/solar-energy.svg",
            formatValue: value => formatNumber(value, 0) + " kW"
        },
        irradiation: {
            description: "Aktuell solinstrålning",
            icon: _constants.scriptBaseUrl + "/svg/irradiation.svg",
            formatValue: value => <>{formatNumber(value, 0)}W/m<sup>2</sup></>
        },
        temperature: {
            description: "Aktuell utomhustemperatur",
            icon: _constants.scriptBaseUrl + "/svg/temperature.svg",
            formatValue: value => <>{formatNumber(value, 1)}&deg;C</>
        },
        dayPrecipitation: {
            description: "Nederbörd idag",
            icon: _constants.scriptBaseUrl + "/svg/rain.svg",
            formatValue: value => formatNumber(value, 0) + " mm"
        },
        windSpeed: {
            description: "Vindhastighet",
            icon: _constants.scriptBaseUrl + "/svg/wind-speed.svg",
            formatValue: value => formatNumber(value, 1) + " m/s"
        },
        // Wikipedia:
        //   Wind direction is measured in degrees clockwise from true north.
        //   Consequently, a wind blowing from the north has a wind direction of 0° (360°); a wind blowing from the east has a wind direction of 90°; a wind blowing from the south has a wind direction of 180°; and a wind blowing from the west has a wind direction of 270°.
        windDirection: {
            description: "Vindriktning",
            icon: _constants.scriptBaseUrl + "/svg/wind-direction.svg",
            formatValue: value => <>{formatNumber(value, 0)}&deg;</>,
            getIconStyle: (value, previousRef, renderCount) => {
                if(value === undefined) {
                    return undefined;
                }

                const isFirst = previousRef.current === undefined;
                value += 180;
                var previousValue = isFirst ? 180 : previousRef.current.windIconRotation;
                if (value - previousValue > 180) {
                    value -= 360;
                } else if (value - previousValue < -180) {
                    value += 360;
                }       
                previousRef.current = { ...(previousRef.current | {}), windIconRotation: value };

                return {
                    transform: `rotate(${value}deg)`,
                    transition: renderCount > 1 ? 'transform 1s ease-in-out' : undefined
                };
            }
        },
        relativeHumidity: {
            description: "Luftfuktighet",
            icon: _constants.scriptBaseUrl + "/svg/relative-humidity.svg",
            formatValue: value => formatNumber(value, 0) + " %"
        }
    };
};

const formatNumber = (number, decimals) => isNaN(number)
    ? ""
    : number.toLocaleString("sv-SE", { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
