import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import fileDownload from 'js-file-download';
import { sub, startOfHour, format } from 'date-fns';
import queryString from 'query-string';
import svLocale from 'date-fns/locale/sv';

import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from 'mui-rff';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';

const apiUrl = "https://stunssolar.azurewebsites.net/api/download-blobs";
const scriptBaseUrl = "https://stunssolar.blob.core.windows.net/web";

const useStyles = makeStyles(theme => ({
    downloadForm: {
        fontFamily: 'Arial',
        fontSize: '14px'
    },
    loadingIcon: {
        width: '1.25em',
        height: '1.25em',
        verticalAlign: 'middle',
        display: 'inline',
        paddingLeft: '1em'
    },
    dateTimePickerContainer: {
        display: 'flex',
        '& > :first-child': {
            marginRight: '0.5em'
        },
        '& > :last-child': {
            marginLeft: '0.5em'
        }
    },
    infoText: {
        margin: '1em 0'
    },
    alert: {
        marginTop: '1em'
    }
}));

const BlobDownload = () => {
    const classes = useStyles();

    const [ initialValues ] = useState({
        "from": sub(startOfHour(new Date()), { hours: 1 }),
        "to": startOfHour(new Date())
    });
    const [ isDownloading, setIsDownloading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(undefined);

    const handleSubmit = formValues => {
        setIsDownloading(true);

        const headers = {};
        headers["Access-Control-Allow-Origin"] = "*";

        const parameters = {
            from: formValues.from.toISOString(),
            to: formValues.to.toISOString()
        };

        const url = apiUrl + "?" + queryString.stringify(parameters);

        fetch(url, headers)
            .then(response => {
                if (response.status === 200) {
                    return response.blob();
                }
                return undefined;
            })
            .then(blob => {
                setIsDownloading(false);
                if (blob) {
                    const fileName = format(formValues.from, "yyyy-MM-dd-HH-mm") + "--" + format(formValues.to, "yyyy-MM-dd-HH-mm") + ".zip";
                    fileDownload(blob, fileName, "application/zip");
                } else {
                    setErrorMessage("Felaktig förfrågan. Du skrev troligen in ett felaktigt tidsspann.");
                }
            });        
    };

    const views = ["date", "year", "month", "hours"];

    const DateTimePickerWithProps = props => <DateTimePicker {...props} views={views} okLabel="Ok" cancelLabel="Avbryt" todayLabel="Idag" ampm={false} disableFuture format="yyyy-MM-dd HH:mm" />

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={svLocale}>
            <Box className={classes.downloadForm}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, invalid }) => (
                        <form onSubmit={handleSubmit}>
                            <Box className={classes.dateTimePickerContainer}>
                                <DateTimePickerWithProps label="Från" name="from" required disabled={isDownloading} />
                                <DateTimePickerWithProps label="Till" name="to" required disabled={isDownloading} />
                            </Box>
                            <Typography className={classes.infoText} variant="body1" gutterBottom>
                                Observera att tidsspannet inte får vara längre än sex timmar.
                            </Typography>

                            <Box>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={invalid || isDownloading}
                                >
                                    Ladda ner
                                </Button>
                                {
                                    isDownloading && <img className={classes.loadingIcon} src={`${scriptBaseUrl}/svg/loading.svg`} alt="Laddar..."/>
                                }
                            </Box>

                            {
                                errorMessage &&
                                (
                                    <Alert severity="error" className={classes.alert}>
                                        {errorMessage}
                                    </Alert>
                                )
                            }
                        </form>
                    )}
                </Form>                        
            </Box>
        </MuiPickersUtilsProvider>
    );
};

export default BlobDownload;
