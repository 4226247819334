import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { format, add, sub, startOfMonth } from 'date-fns';
import queryString from 'query-string';
import svLocale from 'date-fns/locale/sv';

import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker, Checkboxes } from 'mui-rff';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const apiUrl = "https://stunssolar.azurewebsites.net/api/parameter-value-differences";
const scriptBaseUrl = "https://stunssolar.blob.core.windows.net/web";

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '600px'
    },
    loadingIcon: {
        width: '1.25em',
        height: '1.25em',
        verticalAlign: 'middle',
        display: 'inline',
        paddingLeft: '1em'
    },
    dateTimePickerContainer: {
        display: 'flex',
        '& > :first-child': {
            marginRight: '0.5em'
        },
        '& > :last-child': {
            marginLeft: '0.5em'
        },
        marginBottom: '1em'
    },
    alert: {
        marginTop: '1em'
    },
    table: {
        marginTop: '2em'
    },
    jsonAccordion: {
        marginTop: '2em'
    }
}));

const ParameterValueDifferences = () => {
    const classes = useStyles();

    const [ initialValues ] = useState({
        "from": sub(startOfMonth(new Date()), { months: 1 }),
        "to": sub(startOfMonth(new Date()), { days: 1 })
    });
    const [ isDownloading, setIsDownloading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(undefined);
    const [ data, setData ] = useState(undefined);

    const handleSubmit = formValues => {
        setIsDownloading(true);
        setErrorMessage(undefined);
        setData(undefined);

        const headers = {};
        headers["Access-Control-Allow-Origin"] = "*";

        const parameters = {
            parameterName: "totalYield",
            from: format(formValues.from, "yyyy-MM-dd"),
            to: format(add(formValues.to, { days: 1 }), "yyyy-MM-dd"),
            normalize: formValues.normalize
        };

        const url = apiUrl + "?" + queryString.stringify(parameters);

        fetch(url, headers)
            .then(response => response.json())
            .then(json => {
                setIsDownloading(false);
                if(json) {
                    setData(json);
                } else {
                    setErrorMessage("Felaktig förfrågan. Du skrev troligen in ett felaktigt tidsspann.");
                }
            });
    };

    const formatValue = value => value.toFixed(3).replace('.', ',');

    const DatePickerWithProps = props => <DatePicker {...props} okLabel="Ok" cancelLabel="Avbryt" todayLabel="Idag" disableFuture format="yyyy-MM-dd" />

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={svLocale}>
            <Box className={classes.container}>
                <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, invalid }) => (
                        <form onSubmit={handleSubmit}>
                            <Box className={classes.dateTimePickerContainer}>
                                <DatePickerWithProps
                                    label="Startdatum"
                                    name="from"
                                    required disabled={isDownloading}
                                />
                                <DatePickerWithProps
                                    label="Slutdatum"
                                    name="to"
                                    required disabled={isDownloading}
                                />
                                <Checkboxes
                                    name="normalize"
                                    data={{ label: <>Normera&nbsp;värden</>, value: true }}
                                    disabled={isDownloading}
                                />
                            </Box>
                            <Box>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={invalid || isDownloading}
                                >
                                    Ladda ner
                                </Button>
                                {
                                    isDownloading && <img className={classes.loadingIcon} src={`${scriptBaseUrl}/svg/loading.svg`} alt="Laddar..."/>
                                }
                            </Box>
                        </form>
                    )}
                </Form>
                {
                    errorMessage &&
                    (
                        <Alert severity="error" className={classes.alert}>
                            {errorMessage}
                        </Alert>
                    )
                }
                {
                    data &&
                    (
                        <>
                            <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Anläggning</TableCell>
                                        <TableCell align="right">Startvärde</TableCell>
                                        <TableCell align="right">Slutvärde</TableCell>
                                        <TableCell align="right">Differens</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map(item => (
                                            <TableRow key={item.device.deviceId}>
                                                <TableCell>{item.device.identity}</TableCell>
                                                <TableCell align="right">{formatValue(item.startValue)}</TableCell>
                                                <TableCell align="right">{formatValue(item.endValue)}</TableCell>
                                                <TableCell align="right">{formatValue(item.valueDifference)}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            <Accordion className={classes.jsonAccordion}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>JSON</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <pre>{JSON.stringify(data, undefined, 2)}</pre>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                }
            </Box>
        </MuiPickersUtilsProvider>
    );
};

export default ParameterValueDifferences;