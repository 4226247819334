import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createParameterDefinitions } from './logic.js';

import './momentaneous-values.scss';

const MomentaneousValues = ({ parameters }) => {
    const previousRef = useRef(undefined);
    const refreshInterval = 5000;

    const [ data, setData ] = useState(undefined);
    const [ renderCount, setRenderCount ] = useState(0);
    const [ parameterDefinitions ] = useState(createParameterDefinitions());
    
    useEffect(() => {
        const fetchData = () => {
            fetch('https://stunssolar.azurewebsites.net/api/momentaneous-data')
                .then(response => response.json())
                .then(json => {
                    setData(json);
                    setRenderCount(r => r + 1);
                });
        };
        fetchData();
        const interval = window.setInterval(fetchData, refreshInterval);
        return () => window.setInterval(interval);
    }, []);

    const parametersToShow = parameters || ["totalYield", "totalNumberOfTrees", "power", "predictedPower", "irradiation", "temperature", "windSpeed", "windDirection", "relativeHumidity"]

    if(!data) {
        return null;
    }

    return (
        <div className="stuns-solar">
            <div className="parameter-box-container">
                {
                    parametersToShow.map(parameter => {
                        const parameterDefinition = parameterDefinitions[parameter];
                        const parameterValue = parameterDefinition.getValue
                            ? parameterDefinition.getValue(data)
                            : data[parameter];
                        return (
                            <div key={parameter} className="parameter-box" title={parameterDefinition.description}>
                                <img
                                    src={parameterDefinition.icon}
                                    alt={parameterDefinition.description}
                                    className="icon"
                                    style={(parameterDefinition.getIconStyle ? parameterDefinition.getIconStyle(parameterValue, previousRef, renderCount) : undefined)}
                                />
                                <div className="value">
                                    {parameterDefinition.formatValue(parameterValue)}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

MomentaneousValues.propTypes = {
    parameters: PropTypes.array
};

export default MomentaneousValues;
